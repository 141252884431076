@import url(https://fonts.googleapis.com/css?family=Merriweather); 

html, body { 
  background-image: url("fondo2.jpg"); 
  font-family: 'Outfit', sans-serif; 
  padding: 10px; 
  margin-top: 20px;
  background-size: cover;
} 

.Logo{
  padding: 5px;
  padding-left: 30px;
  max-width: 200px;
}

h1 { 
  padding-bottom: 30px;
  text-align: center; 
  color: #ffffff; 
} 

p { 
  text-align: center; 
  color: #ffffff; 
  font-size:x-large;
} 

.divlogo{
  width: auto;
}

form { 
  max-width: 600px; 
  text-align: center; 
  margin: 20px auto; padding: 20px;
	border-radius: 5px;
	background: #b4b7ca;
	box-shadow:0 42px 80px rgba(0, 0, 0, 0.07);
	display: flex;
	flex-direction: column;
} 

input, textarea { 
  border: 0; 
  outline: 0; 
  padding: 1em; 
  border-radius: 8px; 
  display: block;
  width: 100%; 
  margin-top: 1em; 
  resize: none; 
  font-size: large;
} 

#input-submit { 
  color: rgb(255, 255, 255);
  background: rgb(12, 38, 55); 
  cursor: pointer; 
} 

#input-submit:hover { 
  box-shadow: 0 1px 1px 1px rgba(0, 0, 0, 0.2); 
} 

textarea { 
  height: 126px;
}   
  
.half { 
  float: left; 
  width: 48%; 
  margin-bottom: 1em; 
}

.aside {
  padding: 30px;
  padding-left: 50px;
  padding-right: 50px;
  color: #ffffff;
  text-align: center;
}

 .form {
	margin-bottom: 20px;
  width: 304px;
  margin: 20px;
}

.recaptcha{
  align-self: center;
  margin-top: 20px;
  margin-bottom: 20px;
}

.required-error {
  border-color: red !important;
}

.card{
  background-color: #b4b7ca;
  text-align: center; 
  text-size-adjust: normal;
  width: 300px;
  height: auto; /* Cambiado de altura fija a altura automática */
  flex: 1 1 20%;
  overflow: hidden;
  padding: 10px;
}

.card-img-top{
  max-width: 350px;
  text-align: center;
  max-height: 200px;
  padding: 15px;
  width: 70%;
  align-self: center;
}

.card-title{
  font-weight: bolder;
  font-size:x-large;
  background-color: #b4b7ca;
  font-size: 18px;
}

.card-group{
  display: flex;
  flex-wrap: wrap;
}

/* Estilos para pantallas más pequeñas */
@media screen and (max-width: 768px) {
  .card {
    width: 25%;
    height: auto;
    flex: 1 1 50%;
  }
  .card-img-top {
    max-width: 75%; /* Cambiado a ancho máximo del 100% para ajustarse automáticamente al ancho de la pantalla */
  }
  .card-body {
    max-width: 50%; /* Cambiado a ancho máximo del 100% para ajustarse automáticamente al ancho de la pantalla */
  }
  .card-title {
    font-size:small;
  }

  .cf{
    width: 90%;  
  }

}

.footer, #footer {
  color: #ffffff;
  text-align: center;
  font-size: medium;
  padding: 15px;
}

#root{
  align-items: center;
  display: flex;
  justify-content: center;
}

#divinfo{
  max-width: 1000px;
  align-self: center;
  margin:auto;
}

#web{
  align-self: center;  
  max-width: 1000px;
}
